const scrollButton = document.querySelector(".scroll-top");
scrollButton.style.display = 'none'
scrollButton.addEventListener("click", () => {
  $('html, body').animate({
    scrollTop: 0
  }, 400);
});

const scrollHandler = () => {
    const scrollPosition = window.scrollY 
  if ( scrollPosition > 0) {
    scrollButton.style.display = 'block'
  } else {
    scrollButton.style.display = 'none'
  }

}

document.addEventListener('scroll', scrollHandler)
